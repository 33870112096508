var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sop-add-dialog" },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-position": "top",
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: "业务类型", prop: "sopType" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      model: {
                        value: _vm.ruleForm.sopType,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "sopType", $$v)
                        },
                        expression: "ruleForm.sopType",
                      },
                    },
                    _vm._l(_vm.businessTypeList, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { value: item.value, label: item.label },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.uploadLoading,
                  expression: "uploadLoading",
                },
              ],
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "服务模板", prop: "fileList" } },
                [
                  _c("div", { staticClass: "temolate-list-container" }, [
                    _c("div", { staticClass: "title-line" }, [
                      _c("span", [_vm._v("文件名称")]),
                      _c("span", [_vm._v("文件大小")]),
                      _c("span", [_vm._v("文件格式")]),
                      _c("span", [_vm._v("操作")]),
                    ]),
                    _vm.fileList.length === 0
                      ? _c("div", { staticClass: "no-data-line" }, [
                          _c("span", [_vm._v("暂无数据")]),
                        ])
                      : _c(
                          "div",
                          _vm._l(_vm.fileList, function (item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "detail-line" },
                              [
                                _c("span", [_vm._v(_vm._s(item.fileName))]),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.size2Str(item.fileSize))),
                                ]),
                                _c("span", [_vm._v(_vm._s(item.fileFormat))]),
                                _c("span", [
                                  _c("i", {
                                    staticClass: "el-icon-delete",
                                    on: {
                                      click: function ($event) {
                                        return _vm.fileSingleDelete(index)
                                      },
                                    },
                                  }),
                                ]),
                              ]
                            )
                          }),
                          0
                        ),
                  ]),
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        disabled: _vm.fileList.length === 5,
                        "on-success": _vm.uploadSuccess,
                        "on-error": _vm.uploadFail,
                        "before-upload": _vm.beforeUpload,
                        "show-file-list": false,
                        "with-credentials": true,
                        accept: ".pdf, .xls, .xlsx, .ppt, .pptx, .doc, .docx",
                        action: _vm.uploadPath,
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "upload-handler-line",
                          style: {
                            cursor:
                              _vm.fileList.length === 5
                                ? "not-allowed"
                                : "pointer",
                          },
                        },
                        [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                disabled: _vm.fileList.length < 5,
                                effect: "dark",
                                content: "最多支持上传5个服务模板",
                                placement: "top",
                              },
                            },
                            [
                              _c("i", { staticClass: "el-icon-upload" }),
                              _c("span", [_vm._v("点击上传附件")]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 48 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "立刻启用", prop: "status" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.ruleForm.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "status", $$v)
                            },
                            expression: "ruleForm.status",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            { attrs: { border: "", label: "0" } },
                            [_vm._v("启用")]
                          ),
                          _c(
                            "el-radio",
                            { attrs: { border: "", label: "1" } },
                            [_vm._v("禁用")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { align: "right" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                  _c("el-button", { on: { click: _vm.resetForm } }, [
                    _vm._v("取消"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }