import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 * SOP list
 * @param params
 */
export function findSopList(params) {
  return request({
    url: envInfo.bgApp.bmsPath + '/sop/findSopList',
    method: 'get',
    params,
  });
}
/**
 * SOP delete
 * @param params
 */
export function sopDelete(params) {
  return request({
    url: envInfo.bgApp.bmsPath + '/sop/delete',
    method: 'post',
    data: params,
  });
}
/**
 * SOP add
 * @param params
 */
export function sopCreate(params) {
  return request({
    url: envInfo.bgApp.bmsPath + '/sop/create',
    method: 'post',
    data: params,
  });
}
/**
 * SOP update
 * @param params
 */
export function sopUpdate(params) {
  return request({
    url: envInfo.bgApp.bmsPath + '/sop/updateSopStatus',
    method: 'post',
    data: params,
  });
}
